<template>
  <div>
    <v-row align="center" class="mx-1 mb-3">
      <!-- <v-col cols="12" md="2">
            <v-btn @click="openAddDialog()" color="primary" large class=" btn">
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3>إضافة {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>
        <v-spacer /> -->
      <!-- <v-col cols="12" md="3">
        test
        <v-text-field
          class="mr-1"
          v-model="formData.search"
          :label="$t('ism-almaamlh')"
          hide-details=""
          prepend-inner-icon="search"
          dense=""
          outlined=""
          filled=""
        />
      </v-col> -->
      <v-col cols="12" md="3">
        <v-text-field
          v-debounce:500ms="getItems"
          class="mr-1"
          v-model="$global.state.filter.search"
          :label="$t('ism-almaamlh')"
          hide-details=""
          prepend-inner-icon="search"
          dense=""
          outlined=""
          filled=""
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-debounce:500ms="getItems"
          class="mr-1"
          v-model="childData"
          :label="$t('bhth')"
          hide-details=""
          prepend-inner-icon="search"
          dense=""
          outlined=""
          filled=""
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-autocomplete
          @input="getItems()"
          clearable
          v-model="workflowId"
          item-text="name"
          item-value="id"
          :items="$global.state.workflows"
          class="mr-1"
          dense=""
          :label="$t('nwa-almaamlh')"
          :loading="$global.state.loading"
          :no-data-text="$t('no-data')"
          hide-details=""
          prepend-inner-icon="date_range"
          outlined=""
          filled=""
        />
      </v-col>
    </v-row>
    <!-- <v-card id="card"> -->

    <v-card outlined v-if="!isReport">
      <v-container fluid>
        <v-row class="my-1">
          <!-- <v-col cols="12" md="3" > -->
          <v-btn
            @click="openAddDialog(btn)"
            tile
            :color="colors[Math.floor(Math.random() * colors.length)]"
            class="btn"
            style="border-radius: 0px; border: solid"
            dark
            large
            v-for="(btn, index) in workflows"
            :key="index"
          >
            <v-icon>add_circle_outline</v-icon>
            <h3>{{ btn.name }}</h3>
          </v-btn>
          <!-- </v-col> -->
        </v-row>
      </v-container>
    </v-card>
    <v-card outlined v-if="isReport">
      <v-container fluid>
        <!-- {{ $global.state.status[status] }} -->
        <v-tabs
          @change="getItems"
          v-model="status"
          active-class="primary white--text rounded"
        >
          <v-tab
            v-for="status in $global.state.status"
            :value="status.id"
            :key="status.id"
          >
            {{ status.name }}
            <v-chip class="mx-2" v-if="ordersCount != null && status.id">{{
              ordersCount[status.key]
            }}</v-chip>
          </v-tab>
        </v-tabs>
      </v-container>
    </v-card>
    <v-data-table
      class="mytable"
      :items-per-page="$global.state.filter.pageSize"
      :items="items"
      :loading="$global.state.loading"
      :headers="headers()"
      @dblclick:row="($event, { item }) => openDetials(item)"
      hide-default-footer
      :loading-text="$t('loading')"
      :no-data-text="$t('no-data')"
      :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
    >
      <template v-slot:item.workflow.name="{ item }">
        <v-chip outlined label>{{ item.workflow.name }}</v-chip>
      </template>
      <template v-slot:item.status.name="{ item }">
        <v-chip :color="item.status.color" label>{{ item.status.name }}</v-chip>
      </template>
      <template v-slot:item.created="{ item }">
        <div class="grey--text">
          {{
            new Date(item.created).toLocaleDateString("sv").split("T")[0]
          }},<span>{{
            new Date(item.created).toLocaleString().split(",")[1]
          }}</span>
        </div>
      </template>

      <template v-slot:item.expireDate="{ item }">
        <div class="grey--text">
          {{
            new Date(item.expireDate)
              .toLocaleDateString("sv")
              .split("T")[0][0] == 0
              ? " "
              : new Date(item.expireDate).toLocaleDateString("sv").split("T")[0]
          }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- 

            <v-tooltip bottom="">
                <template v-slot:activator="{ on }">
                    <v-btn @click="openEditDialog(item)" v-on="on" icon>
                        <v-icon color="info">
                            edit
                        </v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('edit') }}</span>
            </v-tooltip> -->
        <v-tooltip bottom="" v-if="!isGuest">
          <template v-slot:activator="{ on }">
            <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
              <v-icon color="error"> delete </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("hthf") }}</span>
        </v-tooltip>
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn @click="openDetials(item)" v-on="on" icon>
              <v-icon color="primary"> view_list </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("mtabah-altlb") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- </v-card> -->
    <Pagination />
    <Dialog />
  </div>
</template>

<script>
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
export default {
  computed: {
    isGuest() {
      return this.$route.path.search("guest") > -1;
    },
    isReport() {
      return this.$route.path.search("Report") > -1;
    },
  },
  components: {
    Dialog: () => import("./dialog"),
  },
  data() {
    return {
      formData: {
        formIdentifier: "formA",
        search: "",
      },
      childData: "",
      items: [],
      workflowId: null,
      connection: null,
      workflows: [],
      colors: [
        "#9e0142",
        "#d53e4f",
        "#f46d43",
        "#fdae61",
        "#3288bd",
        "#5e4fa2",
        "#AD1357",
        "#283593",
        "#00695C",
        "#00838F",
        "#01579B",
        "#455A64",
        "#424242",
        "#D84315",
        "#5D4037",
        "#388E3C",
        "#C2185B",
      ],
      status: null,
      ordersCount: null,
    };
  },

  created() {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${this.$config.BASE_URL_PROD}/SignalRHub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Error)
      .build();
    this.connection.Credentials = true;
    this.connection
      .start()
      .then(() => {
        console.log("connected");
        // this.ready = true
      })
      .catch((err) => {
        console.error(err.toString());
      });
  },

  mounted() {
    console.log("this.$store.state.user.userGroups");
    console.log(this.$store.state.user);
    for (let i = 0; i < this.$store.state.user.user.userGroups.length; i++) {
      this.connection.on(
        `group_${this.$store.state.user.user.userGroups[i].groupId}`,
        (send) => {
          // this.connection.on(`group_094dee9d-99f8-4ffe-bbde-95b5e83530c7`, send => {
          try {
            console.log("send");
            this.getItems();
            this.$eventBus.$emit("refresh-notifications");
            console.log(send);
          } catch (e) {
            console.log("e");
            console.log(e);
          }
        }
      );
    }

    this.init();
    // console.log("this.$store.state.user");
    // console.log(this.$store.state.user);
    this.$eventBus.$on(`refresh`, () => {
      this.getItems();
    });

    const storedData = JSON.parse(localStorage.getItem("formData")) || [];
    const formDataForThisForm = storedData.find(
      (entry) => entry.formIdentifier === this.formData.formIdentifier
    );

    if (formDataForThisForm) {
      this.formData = { ...this.formData, ...formDataForThisForm };
    }
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
  },

  methods: {
    deleteItem(id, i) {
      this.$service.deleteItem(id, `Order`, `delete`);
      this.$eventBus.$once(`delete`, () => {
        setTimeout(() => {
          this.getItems();
          this.$eventBus.$emit("refresh-notifications");
        }, 1000);
      });
    },
    headers() {
      let headers = [
        {
          text: this.$t("almstfyd-mn-almaamlh"),
          value: "name",
        },
        // {
        //   text: "الخطوة الحالية",
        //   value: "nodeName",
        // },
        {
          text: this.$t("nwa-almaamlh"),
          value: "workflow.name",
        },
        {
          text: this.$t("alwsf"),
          value: "description",
        },
        {
          text: "الخطوة الحالية",
          value: "nodeName",
        },
      ];
      if (this.isGuest) {
        headers.push({
          text: this.$t("alhalh"),
          value: "status.name",
        });
      }
      return [
        ...headers,
        {
          text: this.$t("tarykh-alinshaa"),
          value: "created",
        },
        // {
        //   text: this.$t('nhayh-alslahyh'),
        //   value: "expireDate",
        // },
        {
          text: "",
          value: "actions",
        },
      ];
    },
    openDetials(item) {
      let url =
        `/${this.isGuest ? "guest/" : ""}${
          this.isReport ? "ordersReport" : "orderNode"
        }/` +
        item.id +
        "/" +
        (item.name != "" ? item.name : "لا يوجد");
      this.$eventBus.$emit("seenOrder", item.id);
      this.$router.push(url);
    },
    async init() {
      await this.getItems();
      await this.getWorkFlows();
      await this.getAllWorkFlows();
    },

    async getItems() {
      this.$global.state.loading = true;
      try {
        let GroupIds = [];
        if (this.$store.state.user.user.userGroups.length > 0) {
          await this.$store.state.user.user.userGroups.forEach((element) => {
            GroupIds.push(element.groupId);
          });
        }
        //${new Date().toLocaleDateString("sv").substr(0,10)}
        // &ExpireDate=${
        //   date.getFullYear() +
        //   "-" +
        //   date.getMonth() +
        //   "-" +
        //   (date.getDate() - 1)
        // }
        let date = new Date();
        let url = `Order?PageIndex=${
          this.$global.state.filter.pageIndex
        }&PageSize=${this.$global.state.filter.pageSize}&Name=${
          this.$global.state.filter.search
        }&IsDelete=false&Sort=desc&UserId=&ChildData=${
          this.childData
        }&WorkflowId=${this.workflowId == null ? "" : this.workflowId}`;
        // if (!this.isReport) {
        for (let j = 0; j < GroupIds.length; j++) {
          url = url + `&GroupIds=${GroupIds[j]}`;
        }
        // }

        if (
          this.isReport &&
          this.$global.state.status[this.status].id != null
        ) {
          url = url + `&StatusId=` + this.$global.state.status[this.status].id;
        }
        if (this.isReport) {
          url = url + `&isReport=true&GetByOrganization=true`;
        } else {
          url = url + `&isReport=false`;
        }
        if (!this.isReport) {
          url =
            url +
            `&StatusId=` +
            this.$global.state.guestStatus.find((v) => v.text == "جار المعالجة")
              .id;
        }
        const res = await this.$http.get(url);
        this.items = res.data.result;
        this.ordersCount = res.data.orderCounts;
        this.$global.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },
    getAllWorkFlows() {
      this.$global.dispatch(`getWorkFlow`);
    },

    // deleteItem(id, i) {
    //     this.$service.deleteItem(id, `${this.$route.meta.endPoint}`, `delete`, 'هل أنت متأكد؟')
    //     this.$eventBus.$once(`delete`, () => {
    //         this.$global.state.regions.splice(i, 1)
    //     })
    // },

    async getWorkFlows() {
      this.$global.state.loading = true;
      try {
        let GroupIds = [];

        if (this.$store.state.user.user.userGroups.length > 0) {
          await this.$store.state.user.user.userGroups.forEach((element) => {
            GroupIds.push(element.groupId);
          });
        }
        let url = `WorkFlow?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}&Search=${this.$global.state.filter.search}&IsDelete=false`;
        for (let j = 0; j < GroupIds.length; j++) {
          url = url + `&GroupIds=${GroupIds[j]}`;
        }
        const res = await this.$http.get(url);
        this.workflows = res.data.result;
        this.$global.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },

    openAddDialog(item) {
      item.dialogType = "add";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(newValue) {
        this.formData.search = this.$global.state.search;
        const storedData = JSON.parse(localStorage.getItem("formData")) || [];
        const index = storedData.findIndex(
          (entry) => entry.formIdentifier === newValue.formIdentifier
        );

        if (index !== -1) {
          storedData[index] = newValue;
        } else {
          storedData.push(newValue);
        }

        localStorage.setItem("formData", JSON.stringify(storedData));
        this.formData.search = this.$global.state.search;
      },
    },
  },
};
</script>

<style scoped>
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgb(71, 71, 71);
}
</style>
